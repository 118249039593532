/* ✅ General Navbar Styling */
.navbar {
  background-color: #708090; /* Slate Gray */
  padding: 5px 10px;
  display: flex;
  align-items: center;
  height: 50px;
  position: relative;
  justify-content: space-between;
}

/* ✅ Logo Styling */
.navbar-logo {
  height: 50px;
  width: auto;
  position: absolute;
  left: 25px;
}

/* ✅ Desktop Navigation */
.nav-links {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 100%;
  padding: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/* ✅ Nav Links */
.nav-links li {
  display: inline;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  padding: 5px 10px;
}

.nav-links a:hover {
  color: #0ABAB5; /* Tiffany Blue */
}

/* ✅ Mobile Styles */
@media (max-width: 768px) {
  /* 🔹 Increase navbar height ONLY on mobile */
  .navbar {
    height: 65px; /* Larger navbar on mobile */
    padding: 10px 10px;
  }

  /* ✅ Show menu when active */
  .nav-links.active {
    display: flex !important;
    padding: 15px 0; /* ✅ Adds extra spacing inside the dropdown */
  }

  /* ✅ Hide desktop nav links on mobile */
  .nav-links:not(.active) {
    display: none !important;
  }

  .nav-links a {
    display: block;
    padding: 12px;
    font-size: 16px;
  }

  /* 🔹 Adjust Mobile Logo Position */
  .navbar-logo {
    height: 40px; /* 🔹 Slightly smaller */
    left: 10px; /* 🔹 Moves logo more to the left */
    top: 14px; /* 🔹 Adjusts vertical positioning */
  }

  /* 🔹 Adjust Mobile Menu Button */
  .menu-toggle {
    display: block;
    background: var(--tiffany-blue);
    color: var(--off-white);
    font-size: 16px; /* 🔹 Slightly smaller */
    font-weight: bold;
    border: none;
    padding: 1px 1px; /* 🔹 Reduce padding */
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
    position: relative;
    right: 10x; /* 🔹 Move inward */
    top: 12px; /* 🔹 Align better with logo */
    z-index: 1001;
  }

  .menu-toggle:hover {
    transform: scale(1.05);
  }
  
}

@media (min-width: 768px) and (max-width: 1024px) {
  .navbar {
    height: 70px;
    padding: 15px 20px;
  }

  .navbar-logo {
    height: 40px; /* 🔹 Slightly smaller */
    left: 10px; /* 🔹 Moves logo more to the left */
    top: 13px; /* 🔹 Adjusts vertical positioning */
  }
  .navbar {
    position: relative; /* ✅ Allows proper scrolling */
  }
}
