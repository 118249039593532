/* ✨ GENERAL STYLES ✨ */
:root {
  --slate-gray: #708090;
  --tiffany-blue: #0ABAB5;
  --bright-coral: #FF6F61;
  --off-white: #F5F5F5;
  --dark-gray: #2C3E50;
}

/* ✅ Restore Scrolling */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevents horizontal scrolling */
  overflow-y: auto; /* ✅ Allows vertical scrolling on all pages */
}

body {
  background-color: var(--off-white);
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.03) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.03) 50%, rgba(0, 0, 0, 0.03) 75%, transparent 75%, transparent);
  background-size: 10px 10px;
}

/* ✨ REMOVE UNWANTED BORDERS */
* {
  box-sizing: border-box;
  border: none;
  outline: none;
}

/* ✨ ENSURE FULL-WIDTH LAYOUT */
.container {
  max-width: 100%;
  padding: 0;
  margin: 0 auto;
}
      
/* ✨ STYLING SECTION DESCRIPTIONS */
.section-description {
  text-align: center;
  font-size: 18px;
  color: var(--slate-gray);
  margin: 10px auto 20px;
  max-width: 800px;
  line-height: 1.6;
}

/* ✨ NAVBAR STYLING */
.navbar {
  background: var(--dark-gray);
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.navbar-logo {
  height: 45px;
  width: auto;
}

/* ✅ Nav Links - Desktop */
.navbar ul {
  list-style: none;
  display: flex;
  gap: 25px;
  padding: 0;
  margin: 0;
}

.navbar a {
  text-decoration: none;
  color: var(--off-white);
  font-size: 18px;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background 0.3s ease, transform 0.2s ease;
}

.navbar a:hover {
  background: var(--tiffany-blue);
  color: white;
  transform: scale(1.05);
}

/* ✅ Mobile Navbar */
.menu-toggle {
  display: none; /* Hidden on desktop */
  background: none;
  border: none;
  font-size: 28px;
  color: var(--off-white);
  cursor: pointer;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    padding: 10px;
  }

  .navbar-logo {
    height: 40px;
  }

  .menu-toggle {
    display: block;
    position: absolute;
    right: 10px;
    top: 11px;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    text-align: center;
    background: var(--dark-gray);
  }

  .nav-links.active {
    display: flex;
  }

  .nav-links a {
    display: block;
    padding: 10px;
    font-size: 16px;
  }
}

/* ✨ ADD SUBTLE NAVBAR ANIMATION */
@keyframes navbarFadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.navbar {
  animation: navbarFadeIn 0.6s ease-in-out;
}

/* ✨ FIX DYNAMIC NAV BAR */
.dynamic-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin: 20px auto;
  max-width: 900px;
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--slate-gray);
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 8px;
  transition: background 0.3s ease, transform 0.2s ease;
}

.nav-item .icon {
  margin-right: 8px;
}

.nav-item:hover {
  background: var(--tiffany-blue);
  transform: scale(1.05);
}

/* ✨ INSTRUCTION TEXT BELOW NAV BAR */
.instruction-text {
  text-align: center;
  font-size: 16px;
  color: var(--slate-gray);
  margin-top: 10px;
}

/* ✅ Mobile Scaling Fixes */
@media (max-width: 768px) {
  .section-description {
    font-size: 16px;
    padding: 0 10px;
  }

  .container {
    padding: 10px;
  }

  .section-title {
    font-size: 24px;
    text-align: center;
  }

  .dynamic-nav {
    flex-direction: column;
    align-items: center;
  }

  .nav-item {
    width: 100%;
    text-align: center;
    padding: 10px;
  }

  /* ✅ Ensure Buttons Fit for Touch */
  .cta-button {
    font-size: 16px;
    padding: 10px 18px;
    width: 100%;
    display: block;
  }
}

/* ✅ Image & Video Scaling */
img, video {
  max-width: 100%;
  height: auto;
  display: block;
}

@media screen and (max-width: 812px) and (orientation: landscape) {
  .navbar {
    position: relative; /* ✅ Allows proper scrolling */
  }
}
