@import "./global.css";

/* ✅ Styling for Introduction Page - Now Matches Subsections */
.introduction-container {
  max-width: 1100px;
  margin: auto;
  padding: 60px 50px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  text-align: left;
  animation: fadeIn 0.6s ease-in-out;
}

/* ✅ Main Section Title */
.intro-title {
  font-size: 42px;
  font-weight: 700;
  color: #222;
  text-align: left;
  margin-bottom: 40px;
  padding-bottom: 10px;
  border-bottom: 4px solid var(--tiffany-blue);
  font-family: "Montserrat", sans-serif;
}

/* ✅ Alternating Backgrounds for Sections */
.intro-section:nth-child(odd) {
  background: #ffffff;
}

.intro-section:nth-child(even) { 
  background: #f8f9fa;
}

/* ✅ Section Styling */
.intro-section {
  padding: 40px;
  border-radius: 6px;
  margin-bottom: 30px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* ✅ Interactive Hover Effect */
.intro-section:hover {
  transform: translateY(-4px);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.08);
}

/* ✅ Center CTA Button */
.cta-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 100%;
}

/* ✅ Call-to-Action Button */
.cta-button {
  display: inline-block;
  padding: 14px 28px;
  background: var(--tiffany-blue);
  color: var(--off-white);
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 10px;
  transition: background 0.3s ease, transform 0.2s ease;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: fit-content;
}

/* ✅ Button Hover Effect */
.cta-button:hover {
  background: var(--bright-coral);
  transform: scale(1.05);
}

/* ✅ Remove Extra Box from CTA */
.cta-section {
  background: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}

/* ✅ Smooth Fade-In Effect */
.introduction-container {
  animation: fadeIn 0.6s ease-in-out;
}

/* ✅ Mobile Optimization */
@media (max-width: 768px) {
  .introduction-container {
    max-width: 95%;
    padding: 40px 20px;
    text-align: left;
  }

  .intro-title {
    font-size: 32px;
    text-align: left;
  }

  .intro-section {
    padding: 30px 20px;
  }

  .intro-section h2 {
    font-size: 24px;
    text-align: left;
  }

  .intro-section p,
  .intro-section ul li {
    font-size: 16px;
    text-align: left;
  }

  .cta-container {
    margin-top: 20px;
    justify-content: left;
    width: 100%;
  }

  .cta-button {
    font-size: 18px;
    padding: 12px 22px;
    width: 100%;
    max-width: 280px; /* ✅ Prevents button from stretching too wide */
  }
}
/* ✅ Footer Styling */
.footer {
  background: var(--dark-gray);
  padding: 15px;
  text-align: center;
  margin-top: 50px;
  border-radius: 6px;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2);
}

.footer-text {
  font-size: 14px;
  color: var(--off-white);
  font-weight: 500;
}

.footer-link {
  color: var(--tiffany-blue);
  text-decoration: none;
  font-weight: bold;
  margin-left: 5px;
  cursor: pointer;
}

.footer-link:hover {
  color: var(--bright-coral);
  text-decoration: underline;
}

/* ✅ Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  backdrop-filter: blur(4px);
}

/* ✅ Modal Content Box */
.modal-content {
  background: var(--off-white);
  padding: 25px;
  border-radius: 8px;
  max-width: 500px;
  text-align: center;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.3s ease-in-out;
}

/* ✅ Modal Title */
.modal-content h2 {
  font-size: 22px;
  color: var(--dark-gray);
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
}

/* ✅ Modal Paragraphs */
.modal-content p {
  font-size: 16px;
  color: var(--slate-gray);
  line-height: 1.6;
  text-align: left;
}

/* ✅ Close Button */
.close-modal {
  background: var(--bright-coral);
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 6px;
  margin-top: 15px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.close-modal:hover {
  background: var(--tiffany-blue);
}

/* ✅ Fade-in Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
/* ✅ Ensure Footer is Mobile-Friendly */
@media (max-width: 768px) {
  .footer {
    padding: 10px;
    font-size: 12px;
  }
  .footer-text {
    font-size: 12px;
  }
  .footer-link {
    font-size: 13px;
  }
}

/* ✅ Ensure Modal is Responsive */
@media (max-width: 600px) {
  .modal-content {
    max-width: 90%;
    padding: 20px;
  }
  .modal-content h2 {
    font-size: 18px;
  }
  .modal-content p {
    font-size: 14px;
  }
  .close-modal {
    font-size: 14px;
    padding: 8px 12px;
  }
}
