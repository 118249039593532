@import "./global.css";

/* ✅ Full Page Background */
.practical-page {
  width: 100%;
  height: 100vh;
  background-color: var(--off-white);
  background-image: url("../assets/minimal-cc-lowres.webp"); /* ✅ Low-quality preview */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  transition: background-image 0.3s ease-in-out; /* ✅ Smooth transition */
}

/* ✅ Swap to High-Quality Image After Preload */
.practical-page.loaded {
  background-image: url("../assets/minimal-cc.webp");
}

/* ✅ Practical Applications Container */
.practical-container {
  position: relative;
  z-index: 1;
  max-width: 900px;
  max-height: 90vh;
  padding: 40px;
  background: rgba(255, 255, 255, 0.85);
  border-radius: 10px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: fadeIn 0.4s ease-in-out;
  overflow: auto;
}

/* ✅ Fade-in effect */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* ✅ Responsive Tweaks */
@media (max-width: 1024px) {
  .practical-container {
    max-width: 90%;
    padding: 50px 30px;
  }

  .section-title {
    font-size: 32px;
  }
}

/* ✅ Mobile Scaling Fixes */
@media (max-width: 768px) {
  .practical-container {
    padding: 30px 20px;
  }

  .section-description {
    font-size: 16px;
    padding: 0 10px;
  }

  .dynamic-nav {
    flex-direction: column;
    align-items: center;
  }

  .nav-item {
    width: 100%;
    text-align: center;
    padding: 10px;
  }
}
