@import "./global.css";

/* ✅ Full Page Background */
.socials-page {
  width: 100%;
  height: 100vh;
  background-color: var(--off-white);
  background-image: url("../assets/minimal-city-lowres.webp"); /* ✅ Low-quality preview */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  transition: background-image 0.4s ease-in-out, filter 0.3s ease;
}

/* ✅ Swap to High-Quality Image After Preload */
.socials-page.loaded {
  background-image: url("../assets/minimal-city.webp")
}

/* ✅ Socials Container (White Box) */
.socials-container {
  position: relative;
  z-index: 1; /* ✅ Ensures content stays on top */
  max-width: 900px;
  padding: 40px;
  background: rgba(255, 255, 255, 0.85); /* ✅ Slight transparency */
  border-radius: 12px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: fadeIn 0.4s ease-in-out;
  overflow: auto; /* ✅ Allows scrolling INSIDE the box */
  max-height: 90vh; /* ✅ Prevents the box from taking up the whole screen */
}

/* ✅ Intro Text */
.socials-intro {
  font-size: 18px;
  color: var(--slate-gray);
  margin-bottom: 30px;
}

/* ✅ Social Media Cards */
.social-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap; /* ✅ Allows wrapping for smaller screens */
}

.social-card {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  color: var(--dark-gray);
  background: rgba(255, 255, 255, 0.9); /* ✅ Slight transparency */
  padding: 15px 25px;
  border-radius: 8px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background 0.3s ease;
}

.social-card:hover {
  transform: scale(1.05);
  background: var(--tiffany-blue);
  color: var(--off-white);
}

/* ✅ Social Media Icons */
.social-icon {
  font-size: 24px;
  color: var(--dark-gray);
}

/* ✅ Instagram-Specific Styling */
.instagram {
  border-bottom: 3px solid #e1306c;
}

/* ✅ Twitter (X)-Specific Styling */
.twitter {
  border-bottom: 3px solid #1da1f2;
}

/* ✅ Call-to-Action Box */


.cta-box h2 {
  font-size: 24px;
  color: black
}

.cta-box p {
  font-size: 16px;
  color: var(--slate-gray);
}

/* ✅ Fade-in effect */
@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(5px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

/* ✅ Apply fade-in animation */
.socials-container {
  animation: fadeIn 0.4s ease-in-out;
}

/* ✅ Responsive Tweaks */
@media (max-width: 1024px) {
  .socials-container {
      max-width: 90%;
      padding: 50px 30px;
  }

  .section-title {
      font-size: 32px;
  }
}

/* ✅ Mobile Optimization */
@media (max-width: 768px) {
  .socials-container {
    padding: 30px 20px;
  }

  .socials-intro {
    font-size: 16px;
  }

  .social-links {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .social-card {
    width: 80%;
    justify-content: center;
  }

  .cta-box {
    padding: 15px;
  }
}
