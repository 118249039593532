@import "./global.css";

/* ✅ RESET MARGINS AND PADDING */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Prevents scrolling */
}

/* ✅ LANDING PAGE CONTAINER */
.landing-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: var(--off-white);
}

/* ✅ BACKGROUND VIDEO */
.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 112vw; /* Ensures full width */
  height: 112vh; /* Ensures full height */
  object-fit: cover; /* Ensures full background fill */
  z-index: -1;
}

/* ✅ STYLIZED HEADING */
.hero h1 {
  font-size: 42px;
  font-weight: 700;
  color: #FFFFFF;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
  margin-bottom: 10px;
  animation: fadeInText 1s ease-in-out;
}

/* ✅ SUBTITLE TEXT */
.hero p {
  font-size: 18px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.95);
  margin-top: 8px;
  line-height: 1.5;
  animation: fadeInText 1.3s ease-in-out;
}

/* ✅ CALL-TO-ACTION BUTTON */
.cta-button {
  display: inline-block;
  padding: 12px 25px;
  background: linear-gradient(135deg, var(--tiffany-blue), #089c91);
  color: var(--off-white);
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 8px;
  transition: background 0.3s ease, transform 0.2s ease;
  box-shadow: 0px 4px 10px rgba(10, 186, 181, 0.3);
  border: none;
  cursor: pointer;
  animation: fadeInText 1.5s ease-in-out;
}

.cta-button:hover {
  transform: scale(1.05);
}

.cta-button:active {
  transform: translateY(1px);
}

/* ✅ FADE-IN ANIMATION */
@keyframes fadeInText {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* ✅ REMOVE ALL HOVER MOVEMENT ON LANDING PAGE */
.landing-page section {
  all: unset;
}

/* ✅ ENSURE NO SECTION MOVEMENT */
.landing-page section:hover {
  transform: none !important;
  box-shadow: none !important;
}

/* ✅ RESPONSIVE DESIGN */
@media (max-width: 768px) {
  .hero {
    max-width: 90%;
    padding: 1.5rem;
  }

  .hero h1 {
    font-size: 32px;
  }

  .hero p {
    font-size: 16px;
  }

  .cta-button {
    font-size: 14px;
    padding: 10px 20px;
  }
}

@media screen and (max-width: 812px) and (orientation: landscape) {
  .cta-button {
    display: block;
    margin: 10px auto; /* Ensures it's centered */
    text-align: center;
  }
}
